<template>
  <section
    class="control-btn lang-toggle"
    :class="{
      'active': showLangList,
      'fixed': isFixedHeader,
    }"
  >
    <div
      class="lang"
    >
      <div
        class="lang__header"
        @click="toggleLangList"
      >
        <p>{{ lang }}</p>
        <img
          src="/img/icons/triangle-down.svg"
          alt="arrow down"
        >
      </div>
      <ul class="lang__list">
        <li
          v-for="loc in locales"
          :key="loc.code"
          @click="showLangList = false"
        >
          <NuxtLink
            :to="switchLocalePath(loc.code)"
          >
            <ClientOnly
              fallback="Русский"
            >
              {{ localNameTextFilter(loc.name) }}
            </ClientOnly>
          </NuxtLink>
        </li>
      </ul>
    </div>
    <!-- <select name="lang">
      <option
        v-for="loc in locales"
        :key="loc.code"
        :value="loc.code"
      >
        <NuxtLink
          :to="switchLocalePath(loc.code)"
        >
          {{ localNameTextFilter(loc.name) }}
        </NuxtLink>
      </option>
    </select> -->
  </section>
</template>

<script setup lang="ts">
const {
  locale,
  locales
} = useI18n();
const switchLocalePath = useSwitchLocalePath();
const $viewport = useViewport();

const emits = defineEmits(['changeOpen'])

defineProps<{
  isFixedHeader?: boolean;
}>();

const availableLocales = computed(() => {
  return locales.value.filter((i) => i.code !== locale.value);
});
function localNameTextFilter (name: string | undefined) {
  if ($viewport.isLessThan('sm')) {
    return name?.substr(0, 2);
  } else {
    return name;
  }
}

const lang = computed(() => {
  return locale.value === 'ru' ? 'RU' : 'EN';
});

const showLangList = ref(false);

function toggleLangList() {
  emits('changeOpen');
  showLangList.value = !showLangList.value;
}
</script>

<style scoped lang="scss">
.lang-toggle {
  position: relative;
	display: flex;
	gap: 20px;

	&.control-btn {
		min-width: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 8px 12px;
		font-size: 13px;
		line-height: 100%;
		font-weight: 500;
		border-radius: 30px;
		background: rgba(255, 255, 255, 0.24);
		cursor: pointer;

    @media screen and (max-width: 991px) {
      .control-btn {
        font-size: 10px;
      }
    }
	}

  &.active {
    & .lang {
      padding: 0 5px;
    }
    &.control-btn {
      border-radius: 16px 16px 0 0;
      background: rgba(255, 255, 255, 0.24);
      padding: 0 5px;
    }
    .lang__header {
      img {
        rotate: 180deg;
        transition: all 0.3s linear;
      }
    }
    .lang__list {
      display: flex;
    }
  }
}

.lang {
  &__header {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    img {
      transition: all 0.3s linear;
    }
  }
  &__list {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    border-radius: 0 0 16px 16px;
    background: rgba(255, 255, 255, 0.24);
    padding: 0 8px 12px 8px;

    & > li:hover {
      text-decoration: underline;
    }
  }
}

.fixed {
  &.control-btn {
    background: rgba(24, 144, 255, 1);
  }

  &.active {
    &.control-btn {
      border-radius: 16px 16px 0 0;
      background: rgba(24, 144, 255, 0.8);
      color: white;
    }
    .lang {
      &__list {
        background: rgba(24, 144, 255, 0.8);
      }
    }
  }
}

</style>
